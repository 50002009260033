export const headList = [
    {name:'系统单号',prop:'systemNumber',width:140,fixed:true},
    {name:'状态',prop:'orderStatusName',fixed:true},
    {name:'批次号',prop:'bacthNumber',fixed:true},
    {name:'平台单号',prop:'orderNumber',width:170},
    {name:'物流商',prop:'shipTrackName',width:170},
    {name:'物流方式',prop:'shipTrackWayName',width:170},
    {name:'标签',prop:'',width:60},
    {name:'店铺',prop:'shopName'},
    {name:'国家',prop:'orderCountry'},
    {name:'平台',prop:'platform'},
    {name:'出库时间',prop:'outTime',width:160},
    {name:'发货员',prop:'outUserName',width:120},
    {name:'目的地',prop:'shipCountry'},
    {name:'税号',prop:''},
    {name:'运单号',prop:'expressNo',width:160},
    {name:'跟踪号',prop:'trackNo',width:160},
    {name:'发货仓库',prop:'shipStorageName'},
    {name:'配货情况',prop:'prepareGoods'},
    {name:'预估运费',prop:'shipPriceEs',},
    {name:'物流运费',prop:'shipPrice'},
    {name:'出库成本',prop:'outPrice'},
    {name:'商品信息',prop:'',width:120},
    {name:'是否验货',prop:'pickUp'},
    {name:'是否称重',prop:'weigh'},
    {name:'面单打印',prop:'printExpress'},
    {name:'订单打印',prop:'printOrder'},
    {name:'包裹尺寸',prop:'packageSize'},
    {name:'预估重量',prop:''},
    {name:'包裹实重',prop:'packageWeight'},
    {name:'包裹计费重',prop:'packageWeight',width:150},
    {name:'创建时间',prop:'createTime',width:160},
    {name:'订单类型',prop:'orderType'},
    {name:'收件人',prop:'shipName'},
    {name:'电话',prop:'shipPhone',width:120},
    {name:'邮编',prop:'shipPostcode'},
    {name:'收货地址',prop:'shipAddress',width:200},
    {name:'订单金额',prop:'totalPrice'},
    {name:'发货时限',prop:'lastShipTime',width:160},
    {name:'客服备注',prop:'csRemarks',width:200},
    {name:'买家留言',prop:'buyerRemarks'},
    {name:'包裹体积',prop:'packageVolume'},
    {name:'加工单号',prop:''},
    {name:'订单来源',prop:'orderFrom'},
]

import request from '@/utils/request'

export function pageInit(){
    return request({
        url:'/api/sale/out/pageInit',
        method:'get',
    })
}
export function listPage(params){
    return request({
        url:'/api/sale/out/listPage',
        method:'get',
        params
    })
}
export function setTrackNo(id,content){
    return request({
        url:`/api/sale/out/setTrackNo/${id}`,
        method:'get',
        params:{
            trackNumber:content
        }
    })
}
export function setExpressNo(id,content){
    return request({
        url:`/api/sale/out/setExpressNo/${id}`,
        method:'get',
        params:{
            expressNo:content
        }
    })
}
