<template>
    <div class="Box">
        <div>
            <el-select v-model="pageInfo.storageId" placeholder="发货仓库" style="width:110px;margin-right:10px;margin-bottom:5px">
                <el-option
                    v-for="item in storageList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="pageInfo.countryId" placeholder="全部国家" style="width:110px;margin-right:10px">
                <el-option
                    v-for="item in countryList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="pageInfo.shopMarketplaceId" placeholder="全部店铺" style="width:140px;margin-right:10px">
                <el-option
                    v-for="item in shopList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id+'-'+item.marketPlaceId">
                </el-option>
            </el-select>
            <el-select v-model="pageInfo.trackId" placeholder="物流商" style="width:110px;margin-right:10px">
                <el-option
                    v-for="item in shipTrackList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="pageInfo.trackWayId" placeholder="物流方式" style="width:110px;margin-right:10px">
                <el-option
                    v-for="item in shipTrackWayList"
                    :key="item.id"
                    :label="item.trackWay"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="pageInfo.orderStatus" placeholder="单据状态" style="width:110px;margin-right:10px">
                <el-option
                    v-for="item in statusList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="pageInfo.dateType" placeholder="创建时间" style="width:110px;margin-right:10px">
                <el-option label="创建时间" value="createTime"></el-option>
                <el-option label="出库时间" value="outTime"></el-option>
            </el-select>
            <el-date-picker
            style="margin-right:10px;position:relative;top:1px"
            v-model="time"
            type="daterange"
            @change="getTime"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
            </el-date-picker>
            <el-input v-model="pageInfo.keyword" placeholder="输入搜索内容" style="width:240px;margin-right:10px;margin-bottom:10px">
                <el-select v-model="pageInfo.searchType" slot='prepend' style="width:100px">
                    <el-option label="系统单号" value="systemNumber"></el-option>
                    <el-option label="平台单号" value="orderNumber"></el-option>
                    <el-option label="销售出库单号" value="outNumber"></el-option>
                    <el-option label="SKU" value="sku"></el-option>
                    <el-option label="品名" value="buyerName"></el-option>
                    <el-option label="运单号" value="expressNumber"></el-option>
                    <el-option label="跟踪号" value="shipTrackNumber"></el-option>
                    <el-option label="MSKU" value="msku"></el-option>
                    <el-option label="ASIN" value="asin"></el-option>
                    <!-- <el-option label="客服备注" value=""></el-option> -->
                </el-select>
            </el-input>
            <el-button type="primary" @click="find" size="mini">查询</el-button><br>
            <!-- <el-button type="primary">更新物流信息</el-button>
            <el-button>补打面单</el-button>
            <el-button>导入更新出库单</el-button> -->
            <el-button @click="uploadOrder=true">补录跟踪号/跟踪号</el-button>
            <TableDialog style="float:right;margin-top:10px" :tableList='headList' @setTableHead="getHead"></TableDialog>
        </div>
        <div class="content">
            <el-table
                height="100%"
                :data="tableData"
                style="width: 100%"
                :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                :header-cell-style="{
                    fontSize: '13px',
                    padding: '9px 0',
                    marginTop: '10px',
                    background: '#EBEEF5',
                }">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    prop="outNumber"
                    label="销售出库单号"
                    fixed="left"
                    width="150">
                    <template slot-scope="scope">
                        <CopyButton :content='scope.row.outNumber'>
                            <el-button slot="copy" @click="todetail(scope.row.orderId)"  type='text'>{{scope.row.outNumber}}</el-button>
                        </CopyButton>
                    </template>
                </el-table-column>
                <el-table-column
                    v-for="item in head"
                    :key='item.prop'
                    :prop="item.prop"
                    :label="item.name"
                    :fixed="item.fixed?'left':false"
                    :min-width="item.width">
                    <template slot-scope="scope">
                        <div v-if="item.name=='运单号'">
                            <el-popover
                            placement="right"
                            width="600"
                            @hide="showNo=''"
                            trigger="click">
                                <div style="width:100%;height:510px">
                                    <iframe  v-if="showNo==scope.row.expressNo" width="100%" height="500px" :src="'https://www.track123.com/iframe/home?trackNos='+scope.row.expressNo" frameborder="0" scrolling='no'></iframe>
                                    <el-button  style="margin-top:-20px" @click="copy('https://www.track123.com/iframe/home?trackNos='+scope.row.trackNo)" type="text">复制连接</el-button>
                                </div>
                                <el-button @click="OrderNo(scope.row.expressNo)" slot="reference" type="text">{{scope.row[item.prop]}}</el-button>
                            </el-popover>
                            <span @click="setOderStart(scope.row.id,scope.row.expressNo,'运单号')" style="margin-left:5px;color:#409EFF" class="el-icon-edit"></span>
                        </div>

                        <div v-else-if="item.name=='跟踪号'">
                            <el-popover
                            placement="right"
                            width="600"
                            @hide="showNo=''"
                            trigger="click">
                                <div style="width:100%;height:510px">
                                    <iframe  v-if="showNo==scope.row.trackNo" width="100%" height="500px" :src="'https://www.track123.com/iframe/home?trackNos='+scope.row.trackNo" frameborder="0" scrolling='no'></iframe>
                                    <el-button  style="margin-top:-20px" @click="copy('https://www.track123.com/iframe/home?trackNos='+scope.row.trackNo)" type="text">复制连接</el-button>
                                </div>
                                <el-button @click="OrderNo(scope.row.trackNo)" slot="reference" type="text">{{scope.row[item.prop]}}</el-button>
                            </el-popover>
                            <span @click="setOderStart(scope.row.id,scope.row.trackNo,'跟踪号')" style="margin-left:5px;color:#409EFF" class="el-icon-edit"></span>
                        </div>
                        <span  v-else-if="item.prop=='pickUp'">
                            <el-radio :disabled='true' v-model="scope.row.pickUp" :label="1">{{ }}</el-radio>
                        </span>
                        <span v-else-if="item.prop=='weigh'">
                            <el-radio :disabled='true' v-model="scope.row.weigh" :label="1">{{ }}</el-radio>
                        </span>
                        <span v-else-if="item.prop=='printExpress'">
                            <el-radio :disabled='true' v-model="scope.row.printExpress" :label="1">{{ }}</el-radio>
                        </span>
                        <span v-else-if="item.prop=='printOrder'">
                            <el-radio :disabled='true' v-model="scope.row.printOrder" :label="1">{{ }}</el-radio>
                        </span>
                        <el-popover
                            placement="bottom"
                            v-else-if="item.name=='商品信息'"
                            width="900"
                            trigger="click">
                            <el-table 
                            :data="scope.row.items"
                            :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                            :header-cell-style="{
                                fontSize: '13px',
                                padding: '9px 0',
                                marginTop: '10px',
                                background: '#EBEEF5',
                            }">
                                <el-table-column width="350" property="date" label="商品信息">
                                    <template slot-scope="i">
                                        <div style="display:flex">
                                            <Picture :url='i.row.url' width='40'></Picture>
                                            <div>
                                                <p class="oneLine" style="width:290px">{{i.row.title}}</p>
                                                <p class="oneLine" style="width:290px">ASIN:{{i.row.asin}}&nbsp;&nbsp;MKSKU:{{i.row.msku}}</p>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" property="name" label="品名/SKU">
                                    <template slot-scope="i">
                                        <p class="oneLine" style="width:100px">{{i.row.productName}}</p>
                                        <p class="oneLine" style="width:100px">{{i.row.sku}}</p>
                                    </template>
                                </el-table-column>
                                <el-table-column width="200" property="address" label="第三方仓产品名/产品编码">
                                    <template slot-scope="i">
                                        <p class="oneLine" style="width:100px">{{i.row.wmsProductName}}</p>
                                        <p class="oneLine" style="width:100px">{{i.row.wmsSku}}</p>
                                    </template>
                                </el-table-column>
                                <el-table-column width="90" property="price" label="单价"></el-table-column>
                                <el-table-column property="count" label="数量"></el-table-column>
                                <el-table-column property="remarks" label="备注"></el-table-column>
                            </el-table>
                            <div slot="reference">
                                <div v-for="(item,index) in scope.row.items" :key="item.id" style="width:50px;display:inline-block;margin-top:10px">
                                    <el-badge :value="item.count" class="item" type="primary">
                                        <Picture style="pointer-events: none" v-if="index<=1" :url='item.url' width='40'></Picture>
                                    </el-badge>
                                    <span v-if="index==2">...</span>
                                </div>
                            </div>
                        </el-popover>
                        <span v-else>{{scope.row[item.prop]}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="text-align:center;border-top:1px solid #E4E7ED;padding:10px">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
        <el-dialog
            :title="'编辑'+ Order.setOrderType"
            :visible.sync="updateOrder"
            width="400px"
            :before-close="updateOrderClose">
            <div>
                <el-form ref="Order" :model="Order" label-width="80px">
                    <el-form-item label="追踪号" prop="trackNumber" :rules="[{required:true,message:'请输入内容',trigger:blur}]">
                        <el-input v-model="Order.trackNumber"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button @click="updateOrderClose">取 消</el-button>
                <el-button type="primary" @click="setOrder">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="导入跟踪号/运单号"
            :visible.sync="uploadOrder"
            width="700px"
            :destroy-on-close='false'
            :before-close="uploadOrderClose">
            <div>
                <el-upload
                class="upload-demo"
                :action="action"
                :headers="mytoken"
                :on-success="uploadSuccess"
                multiple
                :limit="3"
                :on-exceed="handleExceed"
                :file-list="fileList">
                <el-button size="small" type="primary" @click="buttonLoading=true">点击上传</el-button>
                <el-button @click.native="downTemplate" slot="tip" type="text" style="margin-left:15px" class="el-upload__tip">下载模板</el-button>
                </el-upload>
                <ul style="line-height:25px;margin-top:20px">
                    <li>支持导入格式为xlsx的文件(手动修改文件后缀无效)</li>
                    <li>模版中的表头不可更改，不可删除</li>
                    <li>数据记录不要超过5000条</li>
                    <li>包裹号重复，以第一条为准</li>
                    <li>销售出库单号/系统单号重复，以第一条为准</li>
                    <li>仅 “已发货、发货中” 状态的销售出库单支持补录跟踪号</li>
                </ul><br><br>
                <p v-if="faildList.length>0" style="font-size:16px">上传失败项：</p><br>
                <el-table
                    :data="faildList"
                    v-if="faildList.length>0"
                    style="width: 100%"
                    :cell-style="{ fontSize: '13px', padding: '7px 0',textAlign:'center' }"
                    :header-cell-style="{
                        fontSize: '13px',
                        padding: '9px 0',
                        marginTop: '10px',
                        background: '#EBEEF5',
                        textAlign:'center'
                    }">
                    <el-table-column
                        prop="id"
                        label="系统单号"
                        width="width">
                    </el-table-column>
                    <el-table-column
                        prop="value"
                        label="失败原因"
                        width="width">
                    </el-table-column>
                </el-table>
            </div>
            <div slot="footer">
                <el-button @click="uploadOrderClose">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
  headList,
  pageInit,
  listPage,
  setTrackNo,
  setExpressNo
} from '@/api/InventoryManage/salesStorehouse.js'
import TableDialog from '@/components/tableDialog'
export default {
    components:{
        TableDialog
    },
    data(){
        var token = window.sessionStorage.getItem('token')
        return {
            loading:false,
            updateOrder:false,
            buttonLoading:false,
            headList:[],
            head:[],
            tableData:[],
            orderDetial:false,
            uploadOrder:false,

            countryList:[],
            shipTrackList:[],
            shipTrackWayList:[],
            shopList:[],
            statusList:[],
            storageList:[],

            pageInfo:{
                current:1,
                pageSize:10,
                searchType:'systemNumber',
                startDate:'',
                endDate:'',
                keword:'',
                shopMarketplaceId:'',
                countryId:'',
                storageId:'',
                trackId:'',
                trackWayId:'',
                orderStatus:'',
                dateType:'createTime',
            },
            time:[],
            total:0,
            mytoken:{token:token},

            faildList:[],
            Order:{
                trackNumber:'',
                id:'',
                setOrderType:''
            },
            url:'',
            showNo:''

        };
    },
    created(){
        this.headList=headList
        this.head=this.headList
        this.getPageInit()
        this.getListPage()
    },
    computed:{
        action(){
            return process.env.VUE_APP_API_URL+'/api/sale/out/importExpressTrackNo'
        }
    },
    methods:{
        getTime(val){
            console.log(val);
            if(val!=null){
                this.pageInfo.startDate=val[0]
                this.pageInfo.endDate=val[1]
                this.getListPage()
            }else{
                this.pageInfo.startDate=''
                this.pageInfo.endDate=''
                this.getListPage()
            }
        },
        copy(info){
            const input = document.createElement('input');
            input.value = info;//将需要复制的内容赋值给input框
            document.body.appendChild(input);
            input.select()//选中输入框
            document.execCommand('copy')//复制当前选中文本到前切板
            document.body.removeChild(input);
            this.$message.success('复制成功')
        },
        setOderStart(id,No,type){
            this.Order={
                id:id,
                trackNumber:No,
                setOrderType:type,
            }

            this.updateOrder=true
        },
        setOrder(){
            this.$refs.Order.validate(valid=>{
                if(!valid) return ;
                this.buttonLoading=true
                if(this.Order.setOrderType=='跟踪号'){
                    setTrackNo(this.Order.id,this.Order.trackNumber).then(res=>{
                        this.buttonLoading=false
                        if(res.data.code==200){
                            this.$message.success(res.data.message)
                            this.updateOrderClose()
                            this.getListPage()
                        }else{
                            this.$message.error(res.data.message)
                        }
                    })
                }else if(this.Order.setOrderType=='运单号'){
                    setExpressNo(this.Order.id,this.Order.trackNumber).then(res=>{
                        this.buttonLoading=false
                        if(res.data.code==200){
                            this.$message.success(res.data.message)
                            this.updateOrderClose()
                            this.getListPage()
                        }else{
                            this.$message.error(res.data.message)
                        }
                    })
                }
            })
        },
        updateOrderClose(){
            this.$refs.Order.resetFields()
            this.updateOrder=false
        },
        uploadOrderClose(){
            this.faildList=[]
            this.uploadOrder=false
        },
        downTemplate(){
            window.location.href='导入跟踪号模板.xlsx'
        },
        uploadSuccess(res,file,fileList){
            console.log(res);
            if(res.code==200){
                this.$message.success(res.message)
                this.buttonLoading=false
                if(res.data.data.length==0){
                    this.uploadOrder=false
                }else{
                    this.faildList=res.data.data
                }
            }else{
                this.$message.error(res.message)
            }
        },
        getListPage(){
          listPage({...this.pageInfo,[this.pageInfo.searchType]:this.pageInfo.keyword}).then(res=>{
              this.tableData=res.data.values
              this.total=res.data.pageInfo.total
          })  
        },
        handleCurrentChange(val){
        this.pageInfo.current=val
        this.getListPage()
        },
        handleSizeChange(val){
            this.pageInfo.pageSize=val
            this.getListPage()
        },
        getPageInit(){
            pageInit().then(res=>{
                this.countryList=res.data.data.countryList
                this.shipTrackList=res.data.data.shipTrackList
                this.shipTrackWayList=res.data.data.shipTrackWayList
                this.shopList=res.data.data.shopList
                this.statusList=res.data.data.statusList
                this.storageList=res.data.data.storageList
            })
        },
        todetail(orderId){
            this.$router.push({
                path:'/shipmentOrder',
                query:{
                    orderId:orderId
                }
            })
        },
        find(){
            this.pageInfo.current=1
            this.getListPage()
        },
        getHead(val){
            this.head=val
        },
        OrderNo(no){
            this.showNo=no
        }
    }
}
</script>

<style scoped lang='scss'>
.Box{
    width: 100%;
    height: calc(100% - 68px);
    display: flex;
    flex-direction: column;
    .content{
        flex: 1;
        overflow: auto;
        padding-top: 10px;
    }
}
::v-deep .el-input-group__prepend {
    background-color: #fff;
}
::v-deep .el-input-group__append {
    background-color: #fff;
}
::v-deep .el-drawer__header{
    display: none;
}
.info{
    >div{
        width: 100%;
        margin-bottom:40px;
        >p{
            padding-bottom: 10px;
            >span:nth-of-type(1){
                display: inline-block;
                height: 15px;
                border-left: 3px solid #006EFF;
                position: relative;
                top:2px;
                margin-right:5px;
            }
        }
    }
}
::v-deep span .el-radio__input.is-checked .el-radio__inner{
    border-color: #409EFF;
    background: #409EFF;
}
::v-deep .el-badge__content.is-fixed{
    transform: scale(59%);
    right: -4px;
    top: -9px;
    padding: 2px 6px;
    border-radius: 50%;
}
</style>